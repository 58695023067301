@import "./exports";

.container {
    padding: 16px;
    text-align: center;

    .form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        input {
            width: 400px;
            max-width: 90vw;
            height: 25px;
            margin-bottom: 8px; }

        .radioGroup {
            display: flex;
            flex-direction: column; }

        textarea {
            width: 400px;
            max-width: 90vw;
            height: 200px; }

        button {
            width: 150px;
            height: 40px;
            background: $main;
            border: none;
            border-radius: 4px;
            font-family: WorkSans;
            margin: 4px;
            text-decoration: none;
            text-align: center;
            line-height: 40px;
            color: $base;

            &:visited {
                color: $base; }
            &:hover {
                cursor: pointer; } } } }
