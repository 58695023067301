@import "./exports";

.projects {
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: $med) {
        justify-content: center; }

    .heading {
        flex: 0 0 15px;
        min-width: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 16px 0;
        border-left: 1px solid $white;
        padding: 24px;
        border-right: 1px solid $white;
        @media screen and (max-width: $med) {
            flex: 0 0 90vw;
            padding: 8px;
            margin: 16px 0 0 0;
            border: 0;
            border-top: 1px solid $white;
            border-bottom: 1px solid $white;
            height: 32px; }

        p {
            font-size: 48px;
            transform: rotate(-90deg);
            @media screen and (max-width: $med) {
                font-size: 24px;
                transform: unset; } } } }
