@import "./exports.sass";

.wrapper {
    display: flex; }

.container {
    margin: auto;
    height: 100vh;
    max-width: 2000px;
    min-width: 0;
    flex-grow: 1;
    &.menuOpened {
        overflow: hidden; } }

.navBar {
    background: rgba($white, 0.05);
    display: flex;
    height: 50px;
    padding: 10px;
    .menu {
        width: 74px;
        margin-left: auto;
        flex-direction: column;
        justify-content: end;
        align-items: center;
        display: none;
        @media screen and (max-width: $small) {
            display: flex; }
        div {
            width: 35px;
            height: 5px;
            background-color: $main;
            margin: 5px 0; } }
    .links {
        width: 250px;
        display: flex;
        justify-content: space-around;
        @media screen and (max-width: $small) {
            display: none; }
        .navLink {
            text-decoration: none;
            align-content: center;
            display: flex;
            align-items: center;
            width: 74px;
            justify-content: center;
            border-radius: 4px;
            color: $white;
            &:visited {
                color: $white; }
            &:hover {
                color: $base;
                background: $main; } } } }

.headerContainer {
    height: 200px;
    background: rgba($white, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin-top: 4px;
    border-radius: 0 0 4px 4px;
    overflow: hidden;
    white-space: nowrap;
    .logo {
        font-size: 64px;
        color: $main;
        margin: 0;
        padding: 16px; }

    .npm {
        background: rgba($cool-gray, 0.5);
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 12px;
        color: $base; }

    .tagline {
        text-align: center; } }

.popoutMenu {
    display: none;
    width: 0;
    background-color: $base;
    padding-top: 70px;
    border-left: 1px solid $main;
    .navLink {
            text-decoration: none;
            display: flex;
            width: 100%;
            justify-content: flex-end;
            color: $white;
            font-size: 24px;
            line-height: 52px;
            padding-right: 16px;
            &:visited {
                color: $white; }
            &:hover {
                color: $base;
                background: $main; } }
    &.menuOpened {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        min-width: 225px; } }
