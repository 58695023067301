@import "./exports";

.card {
    background: rgba($white, 0.1);
    border-radius: 4px;
    height: 400px;
    width: 400px;
    max-width: 90vw;
    display: flex;
    flex-direction: column;
    padding: 16px;
    margin: 16px;

    .cardHeader {
        border-bottom: 1px solid $main; }

    .cardBody {
        overflow-y: auto; }
    .buttonContainer {
        display: flex;
        justify-content: space-around;
        padding-bottom: 8px;
        margin-top: auto;
        .button {
            width: 50%;
            min-width: 150px;
            height: 40px;
            background: $main;
            border-radius: 4px;
            font-family: WorkSans;
            margin: 8px;
            text-decoration: none;
            text-align: center;
            line-height: 40px;
            color: $base;
            &:visited {
                color: $base; }
            &.secondary {
                background: transparent;
                border: 1px solid $main;
                color: $white; } } }

    .cardFooter {
        font-size: 14px; } }
