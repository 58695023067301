@import "./exports.sass";

@font-face {
    font-family: "WorkSans";
    src: url("../assets/WorkSans-Regular.ttf"); }

@font-face {
    font-family: "Cutive";
    src: url("../assets/CutiveMono-Regular.ttf"); }

body {
    background: $base;
    // height: 100vh
    // width: 100vw
    color: $white;
    font-family: WorkSans;
    line-height: 24px; }

pre {
    font-family: Cutive; }

.hidden {
    display: none; }

::-webkit-scrollbar {
    width: 4px; }

::-webkit-scrollbar-track {
    background-color: transparent; }

::-webkit-scrollbar-thumb {
    background-color: $main;
    border-radius: 20px; }
