.container {
    padding: 16px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .stuff {
        width: 400px;
        word-wrap: break-word;
        padding: 16px; }
    .meFace {
        padding: 16px;
        width: 300px;
        align-self: center; } }
